.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  color: white;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #0E1425;
}

a {
  text-decoration: none;
  color: #61dafb;
  border: #61dafb 3px solid;
  margin: 5px;
  padding: 1em;
}

button {
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #27B1FF;
  color: #27B1FF;
  padding: 1em;
  margin: 5px;
  cursor: pointer;

}

.ack{

font-family: Roboto, "Helvetica Neue", sans-serif;
font-size: 12px;
font-weight: 500;
box-sizing: border-box;
position: relative;
user-select: none;
cursor: pointer;
outline: none;
border: none;
-webkit-tap-highlight-color: rgba(0,0,0,0);
text-decoration: none;
vertical-align: baseline;
text-align: center;
margin: 10px;
min-width: 100px;
border-radius: 4px;
overflow: hidden;
box-shadow: 0 0 #0003,0 0 #00000024,0 0 #0000001f;
text-transform: uppercase;
display: flex;
justify-content: center;
align-items: center;
width: 15%;
white-space: normal;
line-height: 16px;
padding: 3px 8px;
min-height: 36px;
margin-left: 0;
color: #fff;
}

#Normal{
  background-color: #00820d;
  
  }

#Suspicious{
  background-color: rgb(130, 117, 0)!important;
  }

#Loitering{
  background-color: rgb(200, 144, 3)!important;
  }

#Tresspassing{
  background-color: rgb(137, 78, 0) !important;
  }

#Theft{
  background-color: rgb(137, 46, 0) !important;
  }

#Emergency{
  background-color: rgb(137, 0, 0) !important;
}

input .ack {
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #27B1FF;
  color: #27B1FF;
  padding: 1em;
  margin: 5px;
  cursor: pointer;
}

.viewBox {
  display: flex;
  
  justify-content: center;
  max-height: 50vh;
}

.siteDetailsContainer {
  display: flex;
  columns: 3;
  justify-content: center;
  margin: 1em;
  max-height: 30vh;
}

.siteDetailsColumn {
flex-direction: column;
 width: 33%;
 text-align: left;
 background-color: #0000005f;
 margin-left: 10px;
 margin-right: 10px;
 padding: 10px;
 border-radius: 10px;
 border: #757575 1px solid;
 overflow: scroll;
}

.eventThumbnailColumn {
  width: 30%;
  vertical-align: middle;
  border: #757575 1px solid;
  border-radius: 10px;
}


.header {
  display: flex;
  columns: 4;
  max-height: 70px;
  justify-content: center;
  background-color: #000417;
  border-bottom: 1px solid #374151;
  font-size: 1.25em;
  && span {
    flex: 4;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.goTo {
  width: 150px;
  margin: 0.5em;
  padding: 0.5em;
}

.primaryButtonRow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
text-wrap: wrap;
}

.siteDetailsColumn h1 {
  margin: 0;
}

.siteDetailsColumn h2 {
  margin: 0;
}

.siteDetailsColumn h3 {
margin: 0;
}

.siteDetailsColumn h4 {
  margin: 0;
}

.siteDetailsColumn h5 {
margin: 0;
margin-top: 5px;
color: #8e8e8e;
}


.siteDetailsColumn a {
  color: white;
}

.eventThumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  border: #757575 1px solid;
}

.eventThumbnailEnlarge {
  max-width: 95vw;
  max-height: 95vh;
  cursor: pointer;
}

.eventThumbnailDialog {
  width: 90vw;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

::backdrop {
  background-image: linear-gradient(
    45deg,
    magenta,
    rebeccapurple,
    dodgerblue,
    green
  );
  opacity: 0.75;
}